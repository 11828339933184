@import '~theme/variables';

.borderContainer {
  @include breakpoint(sm) {
    border: 0.2rem solid $color-neutral-05;
    border-radius: $border-radius-01;
    overflow: hidden;
  }
}

.map {
  height: 24rem;
  border-radius: $border-radius-01;
  margin-bottom: $space-m;

  @include breakpoint(sm) {
    border-radius: 0;
    height: 36rem;
    margin-bottom: 0;
  }

  &.portrait {
    @include breakpoint(sm) {
      height: 72rem;
    }
  }
}

.clubSection {
  margin-top: 4rem;
}
