@import '~theme/variables';

.card {
  display: block;
  width: 100%;
  border-radius: $border-radius-01;
  overflow: hidden;

  @include breakpoint(sm, max) {
    box-shadow: $box-shadow-01;
  }
}

.card.highlighted {
  border: 2px solid $color-primary;

  .body {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
}

.header {
  position: relative;
  width: 100%;
  padding-bottom: 47%;
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
}

.body {
  position: relative;
  padding: 0.8rem 0;
  text-align: left;
  background-color: $color-neutral-06;

  @include breakpoint(sm, max) {
    padding: 1.6rem 6rem 1.6rem 2.4rem;
  }

  @include breakpoint(sm) {
    padding-right: 4rem;
  }
}

.description {
  @include breakpoint(sm) {
    font-size: 1.2375rem;
    margin-top: 0.4rem;
  }
}

.icon {
  fill: $color-primary;
  position: absolute;
  font-size: 2.4rem;
  right: 2.4rem;
  top: 50%;
  transform: translateY(-50%);

  @include breakpoint(sm) {
    right: 0.8rem;
  }
}

.footer {
  margin-top: 0.6rem;
  display: flex;
  align-items: center;
}

.marker {
  fill: $color-neutral-02;
  font-size: 1.6rem;
  margin-right: 0.8rem;
}
